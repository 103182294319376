import { BundlePlanProps } from "@/data/plans";
import { CountryCode, TypePhoneNumber } from "@/data/regions";

export const bundlePlanUnitedStates: BundlePlanProps = {
    group: "lifetime",

    title: "Offer: Business phone number, 4 users included",
    plans: [
        {
            name: "lifetime2024basicv2",
            country: CountryCode.US,
            group: "lifetime",
            hasTrial: false,
            default: true,
    
            category: "Basic",
            title: "Lifetime basic",
            subtitle: "Pay once, use forever. No more monthly bills.",
            price: "$99",
            priceAmount: 9900,
            priceCurrency: "usd",
            priceDescription: "one time payment",
            interval: "month",
            features: [
                {
                    check: true,
                    title: "1 new local number",
                    description: "additional numbers: $5/mo",
                },
                {
                    check: true,
                    title: "4 users included",
                    description: "each additional: $10/user/mo",
                },
                {
                    check: true,
                    title: "1000 outgoing minutes",
                    description: "per month. Unlimited incoming calls",
                },
                {
                    check: true,
                    title: "300 outgoing sms",
                    description: "per month. Unlimited incoming SMS",
                    typePhoneNumber: TypePhoneNumber.LOCAL,
                },
                {
                    check: false,
                    title: "300 outgoing sms",
                    description: "SMS not supported on toll-free",
                    typePhoneNumber: TypePhoneNumber.TOLL_FREE,
                },
                {
                    check: true,
                    title: "Custom voicemail greetings",
                    description: "5 slots free",
                },
                {
                    check: true,
                    title: "iOS, Android and web apps included",
                    description: "Call & text from mobile or desktop 🖥️",
                },
                {
                    check: false,
                    title: "Hubspot",
                    description: "Sync call and message history with Hubspot",
                },
                { 
                    check: false, 
                    title: "Zapier" 
                },
            ],
            feeDescription: "Includes taxes and carrier fees. No recurring charges.",
            actionTitle: "Continue",

            payButton: "Pay $99",
            payDescription: "One time payment. No recurring charges.",
        },
        {
            name: "lifetime2024prov2",
            country: CountryCode.US,
            group: "lifetime",
            hasTrial: false,
    
            category: "Pro+",
            title: "Lifetime Pro",
            subtitle: "Pay once, use forever. No more monthly bills.",
            price: "$129",
            priceAmount: 12900,
            priceCurrency: "usd",
            priceDescription: "one time payment",
            interval: "month",
            features: [
                {
                    check: true,
                    title: "1 new local number",
                    description: "additional numbers: $5/mo",
                },
                {
                    check: true,
                    title: "4 users included",
                    description: "each additional: $10/user/mo",
                },
                {
                    check: true,
                    title: "Unlimited minutes",
                    description: "incoming and outgoing. ",
                },
                {
                    check: true,
                    title: "900 outgoing sms",
                    description: "per month. Unlimited incoming SMS",
                    typePhoneNumber: TypePhoneNumber.LOCAL,
                },
                {
                    check: false,
                    title: "900 outgoing sms",
                    description: "SMS not supported on toll-free",
                    typePhoneNumber: TypePhoneNumber.TOLL_FREE,
                },
                {
                    check: true,
                    title: "Custom voicemail greetings",
                    description: "5 slots free",
                },
                {
                    check: true,
                    title: "iOS, Android and web apps included",
                    description: "Call & text from mobile or desktop 🖥️",
                },
                {
                    check: true,
                    title: "Hubspot",
                    description: "Sync call and message history with Hubspot",
                },
                { 
                    check: false, 
                    title: "Zapier" 
                },
            ],
            feeDescription: "Includes taxes and carrier fees. No recurring charges.",
            actionTitle: "Continue",

            payButton: "Pay $129",
            payDescription: "One time payment. No recurring charges.",
        },
    ],
}