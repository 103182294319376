import { BundlePlanProps } from "@/data/plans";
import { CountryCode, TypePhoneNumber } from "@/data/regions";

export const bundlePlanCanada: BundlePlanProps = {
    group: "lifetime",

    title: "Offer: Lifetime Deal Business Phone Number 🇨🇦",
    description: "To redeem offer, do not navigate away from this screen.",
    plans: [
        {
            name: "lifetime2024canadabasicv2",
            country: CountryCode.CA,
            group: "lifetime",
            hasTrial: false,
            default: true,
    
            category: "Basic",
            title: "Lifetime Basic 🇨🇦",
            subtitle: "For users who want to pay only once.",
            price: "CA$169",
            priceAmount: 16900,
            priceCurrency: "cad",
            priceDescription: "one time payment",
            interval: "month",
            features: [
                {
                    check: true,
                    title: "1 new local number",
                    description: "additional numbers: CA$7/mo",
                },
                {
                    check: true,
                    title: "4 users included",
                    description: "each additional: CA$13/user/mo",
                },
                {
                    check: true,
                    title: "1000 outgoing minutes",
                    description: "per month, to CA/US numbers. Incoming unlimited calls.",
                },
                {
                    check: true,
                    title: "300 outgoing sms",
                    description: "per month to Canadian numbers. Unlimited incoming SMS.",
                    typePhoneNumber: TypePhoneNumber.LOCAL,
                },
                {
                    check: false,
                    title: "300 outgoing sms",
                    description: "SMS not supported on toll-free",
                    typePhoneNumber: TypePhoneNumber.TOLL_FREE,
                },
                {
                    check: true,
                    title: "Custom voicemail greetings",
                    description: "5 slots free",
                },
                {
                    check: true,
                    title: "iOS, Android and web apps included",
                    description: "Call & text from mobile or desktop 🖥️",
                },
                {
                    check: false,
                    title: "Hubspot",
                    description: "Sync call and message history with Hubspot of each user in your Phone2 workspace",
                },
                { 
                    check: false, 
                    title: "Zapier" 
                },
            ],
            feeDescription: "All taxes, CRTC and carrier fees included.",
            actionTitle: "Continue",

            payButton: "CA$169",
            payDescription: "One time payment. No recurring charges.",
        },
        {
            name: "lifetime2024canadaprov2",
            country: CountryCode.CA,
            group: "lifetime",
            hasTrial: false,
    
            category: "Pro+",
            title: "Lifetime Pro 🇨🇦",
            subtitle: "For users who want to pay only once.",
            price: "CA$199",
            priceAmount: 19900,
            priceCurrency: "cad",
            priceDescription: "one time payment",
            interval: "month",
            features: [
                {
                    check: true,
                    title: "1 new local number",
                    description: "additional numbers: CA$7/mo",
                },
                {
                    check: true,
                    title: "4 users included",
                    description: "each additional: CA$13/user/mo",
                },
                {
                    check: true,
                    title: "1000 outgoing minutes",
                    description: "per month, to CA/US numbers. Incoming unlimited calls.",
                },
                {
                    check: true,
                    title: "600 outgoing sms",
                    description: "per month to Canadian numbers. Unlimited incoming SMS.",
                    typePhoneNumber: TypePhoneNumber.LOCAL,
                },
                {
                    check: false,
                    title: "600 outgoing sms",
                    description: "SMS not supported on toll-free",
                    typePhoneNumber: TypePhoneNumber.TOLL_FREE,
                },
                {
                    check: true,
                    title: "Custom voicemail greetings",
                    description: "5 slots free",
                },
                {
                    check: true,
                    title: "iOS, Android and web apps included",
                    description: "Call & text from mobile or desktop 🖥️",
                },
                {
                    check: true,
                    title: "Hubspot",
                    description: "Sync call and message history with Hubspot of each user in your Phone2 workspace",
                },
                { 
                    check: false, 
                    title: "Zapier" 
                },
            ],
            feeDescription: "All taxes, CRTC and carrier fees included.",
            actionTitle: "Continue",

            payButton: "CA$199",
            payDescription: "One time payment. No recurring charges.",
        },
    ],
}