import { Check, X } from "lucide-react";
import { H2, Large, Muted } from "../typography/typography";
import { PlanProps } from "@/data/plans";
import { TypePhoneNumber } from "@/data/regions";


export function GetContentPlan({ plan, typePhoneNumber, className }: { plan: PlanProps, typePhoneNumber?: TypePhoneNumber, className?: string }) {
  const features =  plan.features.filter((e) => 
    e.typePhoneNumber === undefined ||
    e.typePhoneNumber === typePhoneNumber
  )
  return (
    <div className={className}>
      <div className="flex items-center justify-start space-x-2 mb-4 min-h-10">
        <H2 className="bold">{plan.price}</H2>
        <div className="flex flex-col space-y-0">
          {plan.priceDescription && <Muted>{plan.priceDescription}</Muted>}
          {plan.priceDescription2 && <Muted>{plan.priceDescription2}</Muted>}
        </div>
      </div>
      <ul className="space-y-3">
        {features.map((feature, index) => (
          <PlanFeatureItem
            key={index}
            check={feature.check}
            title={feature.title}
            description={feature.description}
          />
        ))}
      </ul>
    </div>
  );
}


interface PlanFeatureItemProps {
    check: boolean;
    title: string;
    description?: string;
  }
  
  function PlanFeatureItem({ check, title, description }: PlanFeatureItemProps) {
    return (
      <div className="flex items-start space-x-2">
        <div className="min-[24]:">
          {check ? <Check className="mt-0 text-green-500" /> : <X className="mt-0 text-red-500" />}
        </div>
        <div>
          <Large>{title}</Large>
          {description && <Muted>{description}</Muted>}
        </div>
      </div>
    );
  }
  