import { GetBundleSaasPlan, IsSaasPlanName, GetSaasPlanByName } from "@/app/plan/saas";
import { GetBundleLifetimePlan, IsLifetimePlanName, GetLifetimePlanByName } from "@/app/plan/lifetime";
import { BundlePlanProps, PlanProps } from "@/data/plans";

export function GetBundlePlan(type:string, country: string) : BundlePlanProps {  
    return type === 'lifetime' ?
        GetBundleLifetimePlan(country) :
        GetBundleSaasPlan(country) ;
}

export function FindBundlePlan(name:string, country: string) : BundlePlanProps {  
    if (IsSaasPlanName(name, country) ) {
        return GetBundleSaasPlan(country);
    }
    if (IsLifetimePlanName(name, country) ) {
        return GetBundleLifetimePlan(country);
    }
    return GetBundleSaasPlan(country);
}


export function GetPlanByName(planName:string) : PlanProps | undefined {
    return GetSaasPlanByName(planName) || GetLifetimePlanByName(planName);
}

export function SwitchPlanCountry(plan:PlanProps, country: string) : PlanProps | undefined {
    const plans = plan.group === 'lifetime' ? GetBundleLifetimePlan(country).plans : GetBundleSaasPlan(country).plans;
    return plans.find(p => p.category === plan.category && p.interval === plan.interval);
 }